module.exports = {
    common: {
        privacyPolicy: 'Privacy Policy',
        errorVN: "Đã xảy ra lỗi, \nvui lòng thử lại",
        submit: 'Gửi',
        confirm: 'Xác nhận',
        search: 'Tìm kiếm',
        cancel: 'Hủy',
    },
    registration: {
        alreadyRegistrationVN: 'Số điện thoại này đã được đăng ký. Vui lòng liên hệ Nhân viên Cửa hàng để được hỗ trợ',
        registrationStore: 'Tên cửa hàng',
        salutation: 'Danh xưng',
        mrs: "Cô",
        ms: "Chị",
        dr: "Ông",
        mr: "Anh",
        othe: "Khác",
        firstName: 'Tên',
        lastName: 'Họ',
        mobileNumber: 'Số điện thoại di động',
        email: 'Email',
        formValueDateOfBirth: "Sinh Nhật",
        formValueDay: "Ngày",
        formValueMonth: "Tháng",
        formValueYear: "Năm",
        city: 'Thành phố',
        promotionAgree: 'Tôi đồng ý nhận thông tin quảng cáo từ Công ty TNHH Estee Lauder (Việt Nam).',
        pInfoAgree: 'Tôi đã đọc và đồng ý với việc Công ty TNHH Estee Lauder (Việt Nam) và những bên được ủy quyền khác xử lý (bao gồm chuyển ra nước ngoài) dữ liệu cá nhân của tôi theo Chính sách quyền riêng tư của {0} {1}.',
        errMessagePersonalInfo: 'Vui lòng đồng ý với chính sách quyền riêng tư',
        errMessageBirthMonth: 'Vui lòng nhập tháng sinh của bạn',
        errMessagePhone: ' Vui lòng nhập số điện thoại di động của bạn',
        errMessagePhoneInvalid: ' Vui lòng nhập số điện thoại hợp lệ',
        errMessageEmail: 'Vui lòng nhập địa chỉ email hợp lệ',
        errMessageLastName: 'Vui lòng nhập họ của bạn',
        errMessageLastNameInvalid: 'Vui lòng nhập họ hợp lệ',
        errMessageFirstName: 'Vui lòng nhập tên của bạn',
        errMessageFirstNameInvalid: 'Vui lòng nhập tên hợp lệ',
        errMsgGender: 'Vui lòng chọn danh xưng',
        promotionAgree2: 'Đăng ký để bạn có thể nhận thông tin về sản phẩm mới, sự kiện và ưu đãi',
    },
    barcode: {
        info: 'Chào mừng đến với {0}. Vui lòng xuất trình mã vạch này tại quầy.',
        infoVNTxNo: 'Chào mừng đến với {0}, bạn có thể xuất trình mã vạch của mình trong lần ghé thăm tiếp theo.',
    }
}