module.exports = {
    common: {
        error: "Error",
        errorVN: "An error has occurred, \nplease try again",
        privacyPolicy: 'Privacy Policy',
        toastFail: 'Error',
        submit: 'Submit',
        confirm: 'Confirm',
        search: 'Search',
        cancel: 'Cancel',
    },
    enterNumber: {
        secondTitle: "Welcome",
        tips: "Please input your mobile number to receive an one time verification passcode",
        phonePlaceHolder: "Your mobile number",
        phoneRuleMessage: "Please enter a valid mobile number",
        smallTips: "Your number will remain private",
        getOTP: "Get OTP",
        toastSuccess: "Successful",
        toastFail: "Fail",
        readPrivacyPolicy: "I have read and agree to {0}'s privacy policy. (Consider the privacy policy at {1})",
        readPrivacyPolicyForTFAndLL: "I have read and agree to {0}'s {1}.",
        privacyPolicy: "Privacy Policy",
    },
    enterOTP: {
        secondTitle: "Enter your OTP(Ref:{0})",
        tips: "Please enter the six digit one time verification passcode sent via text to your registered mobile.",
        resendOTP: "Resend OTP:",
        invalidOTP: "Invalid OTP",
        OTPRuleMessage: "The OTP you entered was invalid, please re-enter and try again",
        verify: "Verify",
        ok: "Ok",
    },
    registration: {
        alreadyRegistrationVN: 'This mobile number has been registered. Please contact our Store Personnel for assistance',
        promotionAgree2: 'Sign up so you may receive information about new products, events and offers',
        registrationStore: 'Registration Store',
        errMsgGender: 'Please make a selection on salutation',
        errMessagePersonalInfo: 'Please agree to the privacy policy',
        errMessageBirthMonth: 'Please enter your birth month',
        errMessagePhone: 'Please enter your mobile phone number',
        errMessagePhoneInvalid: 'Please enter a valid mobile number',
        errMessageEmail: 'Please enter a valid email address',
        errMessageLastName: 'Please enter your last name',
        errMessageLastNameInvalid: ' Please enter a valid last name',
        errMessageFirstName: 'Please enter your first name',
        errMessageFirstNameInvalid: 'Please enter a valid first name',
        promotionAgree: 'I consent to receiving information promotional communications from Estee Lauder (Vietnam) LLC.',
        pInfoAgree: 'I have read and consent to the processing (including overseas transfer) of my personal data by Estee Lauder (Vietnam) Limited LLC and other authorized persons in accordance with the {0} {1}.',
        city: 'City',
        birthDay: 'BirthDay',
        email: 'Email Address',
        mobileNumber: 'Mobile Phone Number',
        firstName: 'First Name',
        lastName: 'Last Name',
        otherBrands: 'Estée Lauder Companies brands',
        malay: 'Malay',
        chinese: 'Chinese',
        indian: 'Indian',
        other: 'Other',
        counter: 'Counter / Store',
        counter4PH: 'Counter Name',
        counterth: 'Counter',
        salutation: 'Salutation',
        secondTitle: "Multiple Membership Found!",
        noUserSecondTitle: "One More Step",
        tips: "Please complete the form below to confirm your membership details and ensure your loyalty points are awarded for your transactions.",
        formValueTitle: "Title",
        formValueFirstName: "First name",
        formValueLastName: "Last name",
        formValueDateOfBirth: "Date of Birth",
        ageGroup: "Age Group",
        formValueDay: "Day",
        formValueMonth: "Month",
        formValueYear: "Year",
        preLanguageEnglish: 'English',
        preLanguageChinese: 'Chinese',
        preLanguageOption: 'Preferred Language',
        rulerPreLanguage: 'Please enter your preferred language',
        formValueMobileNumber: 'Mobile Phone Example 12-1234 567',
        formValueMobileNumberth: 'Mobile Phone Number',
        formValueMobileNumberoth: 'Other Phone Example 3-1234 5678',
        formValueMobileNumber4ph: 'Mobile Phone Number',
        formValueWechatID: 'Wechat ID',
        formValueEmail: 'Email Address',
        formValueHomePhone: 'Home Phone',
        formValueMobileEmail: "Email",
        formValueMobileEmailth: "Email Address",
        formValueMobileGender: "Gender",
        formValueMobileGenderFemale: "Female",
        formValueMobileGenderMale: "Male",
        secondTips1: "I would like receive news, offers and other marketing communications form, and be contacted for surveys by ",
        secondTips2: " and their products as listed in the Pricacy Notice overleaf via:",
        subscribeAll: "All of the following channels",
        marketiong: "Or the marketing channels selected individually",
        marketiongEmail: "Emails",
        marketiongText: "Text Messages (SMS/WhatsApp)",
        marketiongPhone: "Phone Calls",
        confirm1: "By signing below, I confirm I am 18 years old or above, I wish to register my information with ",
        brandName: "La Mer",
        confirm2: ", that i have read , understood and agreed to be bound by the relevant terms and conditions and the Privacy Notice as set out overleaf regarding how my personal data may be used.",
        smallTips1: "I have read and agree to the ",
        smallTips2: "Privacy Policy ",
        smallTips3: " and ",
        smallTips5: "the membership program's ",
        submit: "Submit",
        continue: "Continue with registration",
        oK: "OK",
        cancle: "Cancel",
        confirm: 'Confirm',
        statementUnderGender1: "I have read and agree to ",
        statementUnderGenderNew: "I have read and agree to {0}'s {1}",
        possessive: "'s",
        possessive1: "'s",
        privacyPolicy: " Privacy Policy",
        period: ".",
        period1: "",
        statementSubscribe1: "I agree to receive news, offers and other marketing communications from, and be contacted for surveys by, ",
        statementSubscribe2: "Cosmetics Pte. Ltd. (operating entity of ",
        statementSubscribe3: "about any brands owned by its parent company, the Estée Lauder Companies Inc., including any brands acquired or launched in the future, and their products of the same kinds as listed in the ",
        statementSubscribe4: "via:",
        statementButton1: "I am interested in joining loyalty program and by ticking this box I confirm that I have read, understood and agree to ",
        statementButton2: " and by ticking this box I confirm that I am of legal adult age or above and I have read, understood and agree to",
        loyaltyProgram: "'s Loyalty Program's Terms and Conditions. ",
        brandName1: 'JO MALONE LONDON',
        rulerFirstname: 'Please enter your first name',
        rulerFirstname1: 'Please enter your first name in English.',
        rulerLastname: 'Please enter your last name',
        rulerLastname1: 'Please enter your last name in English.',
        rulerSalutation: 'Please select a salutation',
        rulerEmail: 'Please enter a valid email address',
        rulerMobile: 'Please enter your mobile phone number',
        rulerBirth: 'Please select your date of birth',
        rulerBirthday: 'Please enter your birth day',
        rulerBirthmonth: 'Please enter your birth month',
        rulerBirthYear: 'Please enter your birth year',
        rulerBirthday4PH: 'Please select your birth day.',
        rulerBirthMonth4PH: 'Please select your birth month.',
        rulerBirthYear4PH: 'Please select your birth year.',
        alreadyRP: 'This mobile number has been registered. Please contact our Store Personnel for assistance.',
        alreadyRLN: 'This last name has been registered. Please contact our Store Personnel for assistance.',
        alreadyRE: 'This email address has been registered. Please contact our Store Personnel for assistance.',
        frontierNewSub1: "Yes! I want to receive promotional communications from {0}, including {1}.",
        frontierNewSub2: "I have read and agree to {0}'s {1}. I am interested in joining {2} Loyalty Program and I confirm that I am of legal adult age or above and I have read, understood and agree to the {3}.",
        loyalty: '{0} Loyalty Program Terms & Conditions',
        newSub1: "Yes! By completing and submitting my information with this registration form, I agree to receive news, offers and other marketing communications from, and be contacted for surveys by, Estee Lauder Malaysia Sdn. Bhd. (operating entity of ",
        newSub2: "), via emails, text messages, phone calls or postal mails, about any brands owned by our parent company, the Estée Lauder Companies Inc., including any brands acquired or launched in the future, and their products of the same kinds as listed in the ",
        ms: "Ms",
        mrs: "Mrs",
        mr: "Mr",
        dr: "Dr",
        othe: "Other",
        emailConfirm: 'Would you like to proceed without an email?',
        yes: 'Yes',
        no: 'No',
        khun: 'Khun',
        miss: 'Miss',
        languageCode: 'Preferred Language',
        languageCode_English: 'English',
        languageCode_Chinese: 'Chinese',
        mobileAlert: 'Please enter mobile number without the area code "0", example 12 – 1234 567',
        otherMobileAlert: 'Please enter other phone number without the area code "0", example 3 – 1234 5678',
        formValueAddress: 'Address',
        formValueSoi: 'Soi',
        formValueRoad: 'Road',
        formValueSudDistrict: 'Sud-District',
        formValueDistrict: 'District',
        formValueProvince: 'Province',
        formValuePostCode: 'Post Code',
        formsomcc: 'I hereby consent to receive news, offers, and other communications, from Elca (Thailand) Co., Ltd. under the Estee Lauder Companies Inc, together with contact for surveys. Including the same type of products as indicated in the Personal Data Protection Policy through various channels such as phone, mail, emails, notifications, other messages or through advertisements showing the products and/or services tailored to my interests on social media and other websites. For a list of these brands, please see ',
        formsomcc2: 'Our Brands – The Estée Lauder Companies Inc. (elcompanies.com)',
        formValuebmama: 'Beauty Advisor / Make Up Artist',
        formValueRace: 'Race',
        formValueCity: 'City',
        formValueZipCode: 'Zip Code',
        formValueState: 'State/Province',
        formValueCountry: 'Country',
        confirmButton: 'OK',
        cancelButton: 'Cancel',
        confirmLineBinding: 'Would you like to proceed with LINE binding to receive exclusive updates?',
        confirmLineBindingYes: 'Yes',
        confirmLineBindingNo: 'No',
        preferredLanguage: {
            display: 'Preferred Language',
            option: {
                english: 'English',
                chinese: 'Chinese',
            }
        },
        agreeConditionsPH: {
            condition1: 'I consent to the collection, use and disclosure of my personal information and sensitive personal information in accordance with the Estée Lauder Companies {0}',
            condition2: 'Yes!  I want to receive promotional communications from {0} and other {1}',
            condition3: 'Estée Lauder Companies brands',
            conditionBBC: 'Yes! I want to receive promotional communications from the {0}',
        },
        alreadyRMobileAndLastName: 'This mobile number with same last name has been registered. Please contact our Store Personnel for assistance.',
        termsAndConditions: "I have reached the age of majority. In addition, I have read and accepted {0} 's Terms and Conditions and the Program Terms and Conditions of Rewards Program {1}",
        loyaltyTermsAndCondition: 'Loyalty Terms and Condition'
    },
    linkConsumer: {
        secondTitle: "Membership added",
        thank: "Thank you, your membership has been successfully added.",
        done: "Done"
    },
    barcode: {
        infoVN: 'Thank you for registration with {0}, Please present this barcode at the counter.',
        infoVNTxNo: 'Welcome to {0}, you may present your barcode on your next visit.',
        info: 'Welcome to {0}. Please present this barcode at the counter.',
        infoMY: 'Welcome to {0}. Please present this barcode at the store/counter.',
        bindConfirmMessage: 'LINE Binding has been completed.'
    }
}
