module.exports = {
    common: {
        faile: "Error",
    },
    registration: {
        counter4PH: '柜台名称',
        salutation: '称号',
        formValueFirstName: '名',
        formValueLastName: '姓',
        formValueDateOfBirth: "出生日期",
        ageGroup: "年龄段",
        formValueDay: "日",
        formValueMonth: "月",
        formValueYear: "年",
        formValueMobileNumber4ph: '手机号码',
        formValueWechatID: '微信ID',
        formValueEmail: '电子邮件',
        formValueHomePhone: '电话号码',
        formValueMobileGender: "性别",
        formValueMobileGenderFemale: "女",
        formValueMobileGenderMale: "男",
        preLanguageEnglish: 'English',
        preLanguageChinese: '中文',
        preLanguageOption: '首选语言',
        rulerPreLanguage: '请选择您的首选语言。',
        submit: "提交",
        oK: "确认",
        cancle: "取消",
        confirm: '确认',
        privacyPolicy: "隐私政策",
        rulerFirstname: '请输入您的名字。',
        rulerLastname: '请输入您的姓氏。',
        rulerEmail: '请输入有效的电子邮件地址',
        rulerMobile: '请输入您的手机号码。',
        rulerBirthday4PH: '请选择您的出生日期。',
        rulerBirthMonth4PH: '请选择您的出生月份。',
        rulerBirthYear4PH: '请选择您的出生年份。',
        rulerSalutation: '请选择您的称号。',
        alreadyRMobileAndLastName: '这个使用相同姓氏的号码已被注册，请联系本店人员协助。',
        ms: "小姐",
        mrs: "女士",
        mr: "先生",
        othe: "其他",
        emailConfirm: '您没有填邮件的情况下继续吗？',
        yes: '是',
        no: '否',
        agreeConditionsPH: {
            condition1: '我同意根据雅诗兰黛公司的{0}、使用和披露我的个人信息和敏感个人信息。',
            condition2: '是！我希望收到来自{0}和其他{1}的促销信息。',
            condition3: '雅诗兰黛公司品牌',
            conditionBBC: '是的！我希望收到{0}的促销信息。',
        },
    },
    barcode: {
        info: '欢迎来到{0}。请在柜台出示条形码。',
    }
}
